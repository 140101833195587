import React from 'react'
import Logo from './components/Logo'
import AccordianMenu from './components/AccordianMenu'

const menuStructure = [
  {
    title: 'WEAPON_PARTS',
    links: [
       {
        title: 'MUZZLE',
        links: [{ title: '01', path: 'log1' },{ title: '02', path: 'log1' },{ title: '03', path: 'log1' }],
        
      }, 
    ],
  },
  {
    title: 'WEAPONS',
    links: [
       {
        title: 'BASEMESH',
        links: [{ title: 'AK_classic', path: 'log1' },{ title: 'AK_bullpup1', path: 'log1' }],
        
      }, 
    ],
  }
]


export default class AppLayout extends React.Component {
  state = { menuOpen: -1, secondaryMenuOpen: -1 }
  render() {
    return (
      <div id="wrapper">
        <div id="appLayout">
          <Logo />
          <AccordianMenu
            menuStructure={menuStructure}
            menuId="nav-menu"
            primaryClass="primary-nav"
            secondaryClass="secondary-nav"
            tertiaryClass="tertiary-nav"
          />
        </div>
        <div id="content">{this.props.children}</div>
      </div>
    )
  }
}
