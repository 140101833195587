import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../assets/data.json'
import { Link } from 'react-router-dom'

console.log()

const Logo = () => (
  <Link as="div" to="/" style={{ display: 'inline-block', marginLeft: -10, cursor: 'pointer' }}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
      }}
      height={115}
      width={115}
    />
  </Link>
)

export default Logo
