/* global marmoset */
import React from 'react'
export default () => {
  return (
    <div>
      <br></br>
      <a id="text3title">MUZZLE_BRAKES1</a>
      <br></br>
      <a classname="gumroad-button" href="https://heavy.gumroad.com/l/handguard1">Buy on</a>
      <br></br>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/WDujeidJg30" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  )
}
