import React from 'react'
import { Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'

export default ({
  menuStructure = [],
  menuId = null,
  primaryClass = null,
  secondaryClass = null,
  tertiaryClass = null,
}) => {
  const [menuOpen, updateMenuOpen] = React.useState(-1)
  const [secondaryMenuOpen, updateSecondaryMenuOpen] = React.useState(-1)
  return (
    <div id={menuId}>
      {menuStructure.map(({ title, links }, i) => (
        <div className={primaryClass} key={i}>
          <span
            onClick={() => {
              updateMenuOpen(menuOpen === i ? -1 : i)
              updateSecondaryMenuOpen(-1)
            }}
          >
            {title}
          </span>
          <Collapse isOpen={menuOpen === i}>
            {links.map(({ title: secondaryTitle, links: secondaryLinks }, ind) => (
              <div className={secondaryClass} key={ind}>
                <span onClick={() => updateSecondaryMenuOpen(secondaryMenuOpen === ind ? -1 : ind)}>
                  {secondaryTitle}
                </span>
                <Collapse isOpen={secondaryMenuOpen === ind}>
                  {secondaryLinks.map(({ title: tertiaryTitle, path }, index) => (
                    <div className={tertiaryClass} key={index}>
                      <Link to={path}>{tertiaryTitle}</Link>
                    </div>
                  ))}
                </Collapse>
              </div>
            ))}
          </Collapse>
        </div>
      ))}
    </div>
  )
}
