import React from 'react'


export default () => (
  <div>
            {/* <h1 id="hometitle">HEAVY_PAYLOAD_ASSETS_&_TUTORIALS</h1> */}
            <img id="home-png" alt="lol" src="/logimages/1xtile.png"></img>
            <img id="home-png" alt="lol" src="/logimages/1xtile.png"></img>
            <br></br>
            <img id="home-png" alt="lol" src="/logimages/1xtile.png"></img>
    
  </div>
)
