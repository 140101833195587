import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AppLayout from './AppLayout'
import HomePage from './pages/HomePage'
import log1 from './pages/log1'

export default () => {
  return (
    <Router>
      <AppLayout>
        <Routes>
          <Route path="/" exact component={HomePage} />
          <Route path="/log1" exact component={log1} />
        </Routes>
      </AppLayout>
    </Router>
  )
}
